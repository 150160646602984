import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NavBar } from '../components/NavBar';

export const StaticPage = ({ year }) => {
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('load', function () {
      setLoading(false);
    });
    // or if 3 seconds have passed
    setTimeout(function () {
      setLoading(false);
    }, 3000);
  }, []);

  let html2019;
  if (!!!slug) {
    // eslint-disable-next-line import/no-webpack-loader-syntax
    const htmlModule = require('raw-loader!../' + year + '/index.html');
    html2019 = htmlModule.default;
  } else {
    const htmlModule = require('raw-loader!../' + year + '/' + slug + '.html');
    html2019 = htmlModule.default;
  }

  return (
    <>
      <div
        class={`w-screen h-screen fixed top-0 left-0 bg-gray-50 right-0 z-[9999]
        ${!loading ? 'opacity-0 pointer-events-none' : 'opacity-100'}
        transition-opacity duration-500 ease-in-out
        `}
      >
        <div class="w-full h-full flex items-center justify-center">
          <div class="w-20 h-20 border-4 border-t-sv-yellow border-r-sv-green border-l-sv-light-blue border-b-sv-crimson rounded-full animate-spin"></div>
        </div>
      </div>
      <style>
        {`
        html {
            font-size: 16px !important;
        }
        `}
      </style>
      <NavBar year={year} />
      <div dangerouslySetInnerHTML={{ __html: html2019 }} />
    </>
  );
};
