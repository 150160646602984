import { useParams, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import sanityClient, { previewClient } from '../client.js';
import { PortableText } from '@portabletext/react';
import { NavBar } from '../components/NavBar.jsx';
import imageUrlBuilder from '@sanity/image-url';
import { Infographic } from '../components/Infographic.jsx';
import { Footer } from '../components/Footer.jsx';
import debounce from 'lodash.debounce';
import { Helmet } from 'react-helmet';
import { Loading } from '../components/Loading.jsx';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export const StoryKloster = ({ year }) => {
  let location = useLocation();

  const [postData, setPostData] = useState(null);
  // if there are two params, set the first to year and the second to slug
  const { slug } = useParams();
  const [preview, setPreview] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [postId, setPostId] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);

  let queryParams;
  if (typeof window !== 'undefined') {
    queryParams = new URLSearchParams(window.location.search);
  }

  const idQuery = /* js */ `*[slug.current == "${slug}"]{
    _id,
  }`;

  const draftQuery = /* js */ `
  *[slug.current == "${slug}"]{
    title,
    slug,
    "mainImage": mainImage.asset->{
      _id,
      url,
      alt,
    },
    imageColorStart,
    imageColorEnd,
    firstByline,
    secondByline,
    body,
    infographicOverline,
    infographicTitle,
    iconColor,
    citations,
    video,
    seoDescription,
    seoImage,
    timeToRead,
    cardImage,
    infographics[]{
      title,
      description,
      icon,
    },
  }`;

  const query = /* js */ `
  *[slug.current == "${slug}"]{
    title,
    slug,
    "mainImage": mainImage.asset->{
      _id,
      url,
      alt,
    },
    imageColorStart,
    imageColorEnd,
    firstByline,
    secondByline,
    body,
    infographicOverline,
    infographicTitle,
    iconColor,
    citations,
    video,
    seoDescription,
    seoImage,
    timeToRead,
    cardImage,
    infographics[]{
      title,
      description,
      icon,
    },
  }`;

  const [story, setStory] = useState(null);

  useEffect(() => {
    if (window.__REACT_SNAPSHOT_IMPORT__) {
      return window.__REACT_SNAPSHOT_IMPORT__(async () => {
        const data = await sanityClient.fetch(query);
        setStory(data[0]);
      });
    }

    sanityClient.fetch(query).then(data => {
      setStory(data[0]);
      console.log(data[0]);
    });
  }, [query]);

  function getSchool() {
    sanityClient
      .fetch(query)
      .then(data => {
        setStory(data[0]);
        console.log('data', data[0]);
      })
      .catch(console.error);
  }

  function getSchoolPreview() {
    console.log('getSchoolPreview');
    previewClient
      .fetch(query)
      .then(data => {
        console.log('data', data[0]);
        setStory(data[0]);
      })
      .catch(console.error);
    previewClient.listen(query).subscribe(data => {
      setStory(data.result);
    });
  }

  // debounce getSchoolDraftPreview to prevent multiple requests
  const getSchoolDraftPreview = debounce(() => {
    console.log('getSchoolDraftPreview');
    previewClient
      .fetch(draftQuery)
      .then(data => {
        setStory(data[0]);
        console.log('data', data[0]);
      })
      .catch(console.error);
  }, 1000);

  function watchSchool() {
    console.log('watching school');
    previewClient.fetch(idQuery).then(data => {
      setPostId(data[0]._id);
    });
  }

  function listenDraft() {
    console.log('listening draft');
    previewClient.listen(draftQuery).subscribe(data => {
      getSchoolDraftPreview();
    });
  }

  function checkForDraft(postId) {
    console.log('checking for draft');
    sanityClient
      .fetch(
        /* js */ `
          *[_id == "drafts.${postId}"]{
            _id,
          }`
      )
      .then(draft => {
        console.log('draft', draft);
        if (draft.length > 0) {
          console.log('draft found');
          getSchoolDraftPreview();
          listenDraft();
        } else {
          console.log('no draft found');
          getSchoolPreview();
        }
      });
  }

  useEffect(() => {
    if (!!postId) {
      console.log('post id', postId);
      checkForDraft(postId);
    }
  }, [postId]);

  // console log slug
  useEffect(() => {
    console.log('slug', slug);
  }, [slug]);


  useEffect(() => {
    setPreview(queryParams.get('livepreview'));
    if (queryParams.get('livepreview')) {
      watchSchool();
    } else {
      getSchool();
    }
  }, [location]);

  // bg-[#862633]
  // bg-[#003b5c]
  // bg-[#77c5d5]
  // bg-[#ffc845]
  // bg-[#8f993e]
  // bg-[#ff7f41]

  // from-[#862633]
  // from-[#003b5c]
  // from-[#77c5d5]
  // from-[#ffc845]
  // from-[#8f993e]
  // from-[#ff7f41]

  // to-[#862633]
  // to-[#003b5c]
  // to-[#77c5d5]
  // to-[#ffc845]
  // to-[#8f993e]
  // to-[#ff7f41]

  return (
    <>
      <div>
        <NavBar year={year} />
        {/* Use story.video to render a video iframe from a youtube or vimeo link */}
        {story?.video && (
          <div class="w-full relative inset-0 flex flex-col items-center justify-center py-12 lg:py-24 lg:pb-96 overflow-hidden shadow-inner bg-black bg-gradient-to-tr from-gray-900 to-sv-navy/70">
            <img
              class="absolute inset-0 mix-blend-overlay filter grayscale opacity-10 object-cover w-full h-full"
              src="https://images.unsplash.com/photo-1627627256672-027a4613d028?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2374&q=80"
            />
            <div class="w-full max-w-7xl px-8 relative text-white">
              <div
                class="rounded-2xl transition-all duration-500 hover:shadow-2xl overflow-hidden"
                style={{
                  padding: '56.25% 0px 0px',
                  position: 'relative',
                }}
              >
                {story?.video && story?.video?.includes('youtube') && (
                  <iframe
                    src={story?.video
                      ?.replace('watch?v=', 'embed/')
                      .replace('youtu.be/', 'youtube.com/embed/')}
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: '0px',
                      left: '0px',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                )}
                {story?.video && story?.video?.includes('vimeo') && (
                  <iframe
                    src={story?.video?.replace(
                      'vimeo.com/',
                      'player.vimeo.com/video/'
                    )}
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: '0px',
                      left: '0px',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                )}
              </div>
              <h3 className="mt-8 font-condensed uppercase text-lg lg:text-2xl font-light text-sv-yellow">
                Beyond Compare
              </h3>
              <h4 className="mb-8 font-bold font-display uppercase text-3xl lg:text-5xl">
                They Call Him Coach
              </h4>
              <p className="font-body max-w-3xl lg:text-lg leading-loose">
                Jeff Kloster has been a valued member of the Longmont High
                School community since 1991. The 29 years of head coaching the
                boys basketball program has come with many accolades and
                memories. We honor Coach Kloster for the dedication to the
                countless students and players that have been in his classroom
                and on his courts. His imprint is truly beyond compare.
              </p>
            </div>
          </div>
        )}
        <div
          class={`w-screen h-screen fixed top-0 left-0 bg-gray-50 right-0 z-10
        ${story ? 'opacity-0 pointer-events-none' : 'opacity-100'}
        transition-opacity duration-500 ease-in-out
        `}
        >
          <div class="w-full h-full flex items-center justify-center">
            <div class="w-20 h-20 border-4 border-t-sv-yellow border-r-sv-green border-l-sv-light-blue border-b-sv-crimson rounded-full animate-spin"></div>
          </div>
        </div>

        <div class="relative max-w-7xl w-full mx-auto md:px-8 md:pt-16 lg:pb-32 md:-mt-48 lg:-mt-72">
          <div class="relative flex flex-col lg:flex-row w-full">
            <div class="rounded-2xl relative flex">
              <div class="bg-white md:rounded-lg p-8 lg:p-16 shadow-2xl w-full sm:w-auto mx-auto relative inline-block">
                <div class="w-full lg:w-auto h-auto max-w-prose mx-auto lg:mx-0 rounded-md relative">
                  <div class="flex flex-col-reverse items-start md:flex-row md:items-center">
                    <div class="flex flex-col">
                      <h1 class="m-0 p-0">
                        <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl font-display">
                          {story?.title}
                        </span>
                      </h1>
                      <div class="w-16 border-t-4 border-sv-green mt-6 mb-2"></div>
                      <p class="m-0 p-0 text-sv-crimson font-condensed uppercase font-bold">
                        By {story?.firstByline}
                      </p>
                      <p class="m-0 p-0 text-sv-crimson font-condensed uppercase">
                        {story?.secondByline}
                      </p>
                    </div>
                  </div>
                  <div class="prose lg:prose-lg max-w-prose mt-8 prose-headings:font-display prose-headings:text-sv-crimson">
                    <PortableText
                      value={story?.body}
                      components={
                        // render quote block
                        {
                          types: {
                            quote: ({ children, value }) => (
                              <blockquote
                                cite="Benjamin Franklin"
                                className="text-2xl my-6 pl-2 py-2 font-light border-sv-navy leading-relaxed"
                              >
                                "{value?.quote}"
                                <span className="font-bold text-sv-crimson uppercase block mt-3 text-lg not-italic">
                                  {value?.originator}
                                </span>
                              </blockquote>
                            ),
                            image: ({ children, value }) => (
                              <div class="flex flex-col items-center mb-8">
                                {value && (
                                  <>
                                    <img
                                      src={urlFor(value)
                                        .width(800)
                                        .height(600)
                                        .fit('crop')
                                        .url()}
                                      className="rounded-xl"
                                    />
                                    <span class="text-sm font-light text-gray-500 mt-0">
                                      {value?.caption}
                                    </span>
                                  </>
                                )}
                              </div>
                            ),
                          },
                        }
                      }
                    />

                    {story?.citations && (
                      <p class="citations font-light text-sm border-t pt-4 mt-12 border-sv-green">
                        {story?.citations}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {story?.infographicTitle && story?.infographics?.length > 0 && (
              <div class="mb-8 mt-32 lg:mt-96 relative lg:w-2/5">
                <div class="px-4 sticky top-24 space-y-6 pb-16 mb-6 overflow-y-auto lg:max-h-[calc(100vh-7em)]">
                  <div class="px-4 text-gray-900 text-2xl border-t-2 border-b-2 border-gray-300 font-body uppercase font-bold text-left py-4">
                    <p class="text-base font-display font-light">
                      {story?.infographicOverline || 'By the Numbers'}
                    </p>
                    {story?.infographicTitle && (
                      <p class="font-sans text-2xl font-black">
                        {story?.infographicTitle}
                      </p>
                    )}
                  </div>
                  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-1">
                    {story?.infographics?.map((infographic, index) => (
                      <Infographic
                        key={index}
                        title={infographic.title}
                        description={infographic.description}
                        icon={infographic.icon}
                        color={story?.iconColor?.value}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />

      {preview && (
        <div
          className={`max-w-[26rem] sm:max-w-[810px] fixed bottom-0 left-0 z-10 transform transition-all duration-500 ml-2 mb-2 ${
            showCard ? 'translate-y-0' : 'translate-y-[calc(100%-2.5rem)]'
          }`}
          onClick={() => setShowCard(!showCard)}
        >
          <div className="bg-black text-white px-4 py-2 rounded-full inline-block cursor-pointer mb-2 min-w-[170px] hover:bg-gray-800 text-center">
            {showCard ? 'Hide' : 'Show'} Card Preview
          </div>
          <div className="flex gap-2 flex-row mt-1">
            <div
              className={`outline-none border-none visited:opacity-50 hover:opacity-100 flex flex-col overflow-hidden transition-all shadow-sm hover:rounded-none focus:rounded-none duration-500 group hover:-translate-y-1 focus:-translate-y-1 transform motion-reduce:transition-none rounded-3xl hover:shadow-2xl focus:shadow-2xl`}
              style={{
                filter: 'blur(0px)',
              }}
            >
              <div class="flex-shrink-0 relative overflow-hidden bg-black">
                {story?.mainImage ? (
                  <img
                    class="h-72 opacity-100 md:h-96 w-full object-cover transform filter transition-all duration-500 group-hover:grayscale-0 group-focus:grayscale-0 group-hover:-translate-y-12 group-focus:-translate-y-12 group-hover:scale-105 group-focus:scale-105"
                    src={
                      story?.cardImage
                        ? urlFor(story?.cardImage)?.width(800).url()
                        : urlFor(story?.mainImage)?.width(800).url()
                    }
                    alt={story?.mainImage?.alt}
                  />
                ) : (
                  <div class="h-72 opacity-100 md:h-96 w-full object-cover transform filter transition-all duration-500 group-hover:grayscale-0 group-focus:grayscale-0 group-hover:-translate-y-12 group-focus:-translate-y-12 group-hover:scale-105 group-focus:scale-105"></div>
                )}
                <div class="absolute left-0 right-0 bottom-0 h-1/2 bg-gradient-to-t opacity-70 from-black to-transparent group-hover:h-full transition-all duration-500 group-hover:opacity-100"></div>
              </div>
              <div class="absolute transform transition-all group-focus:translate-y-0 duration-500 bottom-0 pointer-events-none w-full translate-y-40 group-hover:translate-y-0">
                <div class="flex-1 bg-transparent p-6 flex flex-col justify-between">
                  <div class="flex-1">
                    <p class="text-sm font-medium text-white">
                      <span class="">Make a Difference.</span>
                    </p>
                    <div class="block mt-2">
                      <p class="text-xl font-semibold text-white">
                        {story?.title}
                      </p>
                      <p class="mt-3 text-base text-gray-100 line-clamp-3">
                        {story?.seoDescription}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="p-6 flex items-center bg-gray-50">
                  <div class="flex justify-between w-full">
                    <p class="text-sm font-medium text-gray-900">
                      <span class="hover:underline">{story?.firstByline}</span>
                    </p>
                    {story?.timeToRead && (
                      <div class="flex space-x-1 text-sm text-gray-500">
                        <span>{story?.timeToRead} min read </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`outline-none border-none visited:opacity-50 opacity-100 flex flex-col overflow-hidden transition-all shadow-sm rounded-none focus:rounded-none duration-500 group -translate-y-1 focus:-translate-y-1 transform motion-reduce:transition-none rounded-3xl shadow-2xl focus:shadow-2xl`}
              style={{
                filter: 'blur(0px)',
              }}
            >
              <div class="flex-shrink-0 relative overflow-hidden bg-black">
                {story?.mainImage ? (
                  <img
                    class="h-72 opacity-100 md:h-96 w-full object-cover transform filter transition-all duration-500 grayscale-0 group-focus:grayscale-0 -translate-y-12 group-focus:-translate-y-12 scale-105 group-focus:scale-105"
                    src={
                      story?.cardImage
                        ? urlFor(story?.cardImage)?.width(800).url()
                        : urlFor(story?.mainImage)?.width(800).url()
                    }
                    alt={story?.mainImage?.alt}
                  />
                ) : (
                  <div class="h-72 opacity-100 md:h-96 w-full object-cover transform filter transition-all duration-500 grayscale-0 group-focus:grayscale-0 -translate-y-12 group-focus:-translate-y-12 scale-105 group-focus:scale-105"></div>
                )}
                <div class="absolute left-0 right-0 bottom-0 h-1/2 bg-gradient-to-t opacity-70 from-black to-transparent h-full transition-all duration-500 opacity-100"></div>
              </div>
              <div class="absolute transform transition-all group-focus:translate-y-0 duration-500 bottom-0 pointer-events-none w-full translate-y-40 translate-y-0">
                <div class="flex-1 bg-transparent p-6 flex flex-col justify-between">
                  <div class="flex-1">
                    <p class="text-sm font-medium text-white">
                      <span class="">Make a Difference.</span>
                    </p>
                    <div class="block mt-2">
                      <p class="text-xl font-semibold text-white">
                        {story?.title}
                      </p>
                      <p class="mt-3 text-base text-gray-100 line-clamp-3">
                        {story?.seoDescription}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="p-6 flex items-center bg-gray-50">
                  <div class="flex justify-between w-full">
                    <p class="text-sm font-medium text-gray-900">
                      <span class="hover:underline">{story?.firstByline}</span>
                    </p>
                    {story?.timeToRead && (
                      <div class="flex space-x-1 text-sm text-gray-500">
                        <span>{story?.timeToRead} min read </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
