import { useParams, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import sanityClient, { previewClient } from '../client.js'
import { PortableText } from '@portabletext/react'
import { NavBar } from '../components/NavBar.jsx'
import imageUrlBuilder from '@sanity/image-url'
import { Infographic } from '../components/Infographic.jsx'
import { Footer } from '../components/Footer.jsx'
import debounce from 'lodash.debounce'
import { BondInfographic } from '../sections/special/BondInfographic.jsx'

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}

export const Story = ({ year }) => {
  let location = useLocation()

  // if there are two params, set the first to year and the second to slug
  const { slug } = useParams()
  const [preview, setPreview] = useState(false)
  const [showCard, setShowCard] = useState(false)
  const [postId, setPostId] = useState(null)

  let queryParams
  if (typeof window !== 'undefined') {
    queryParams = new URLSearchParams(window.location.search)
  }

  const idQuery = /* js */ `*[slug.current == "${slug}"]{
    _id,
  }`

  const draftQuery = /* js */ `
  *[slug.current == "${slug}"]{
    title,
    slug,
    "mainImage": mainImage.asset->{
      _id,
      url,
      alt,
    },
    imageColorStart,
    imageColorEnd,
    firstByline,
    secondByline,
    body,
    infographicOverline,
    infographicTitle,
    iconColor,
    citations,
        video,
    featuredVideo,
    videoTitle,
    videoDescription,
    videoOverline,
    video,
    seoDescription,
    seoImage,
    timeToRead,
    cardImage,
    infographics[]{
      title,
      description,
      icon,
    },
  }`

  const query = /* js */ `
  *[slug.current == "${slug}"]{
    title,
    slug,
    "mainImage": mainImage.asset->{
      _id,
      url,
      alt,
    },
    imageColorStart,
    imageColorEnd,
    firstByline,
    secondByline,
    body,
    infographicOverline,
    infographicTitle,
    iconColor,
    citations,
    video,
    featuredVideo,
    videoTitle,
    videoDescription,
    videoOverline,
    video,
    seoDescription,
    seoImage,
    timeToRead,
    cardImage,
    infographics[]{
      title,
      description,
      icon,
    },
  }`

  const [story, setStory] = useState(null)

  useEffect(() => {
    if (window.__REACT_SNAPSHOT_IMPORT__) {
      return window.__REACT_SNAPSHOT_IMPORT__(async () => {
        const data = await sanityClient.fetch(query)
        setStory(data[0])
      })
    }

    sanityClient.fetch(query).then((data) => {
      setStory(data[0])
    })
  }, [query])

  function getSchool() {
    sanityClient
      .fetch(query)
      .then((data) => {
        setStory(data[0])
      })
      .catch(console.error)
  }

  function getSchoolPreview() {
    previewClient
      .fetch(query)
      .then((data) => {
        setStory(data[0])
      })
      .catch(console.error)
    previewClient.listen(query).subscribe((data) => {
      setStory(data.result)
    })
  }

  // debounce getSchoolDraftPreview to prevent multiple requests
  const getSchoolDraftPreview = debounce(() => {
    previewClient
      .fetch(draftQuery)
      .then((data) => {
        setStory(data[0])
      })
      .catch(console.error)
  }, 1000)

  function watchSchool() {
    previewClient.fetch(idQuery).then((data) => {
      setPostId(data[0]._id)
    })
  }

  function listenDraft() {
    previewClient.listen(draftQuery).subscribe((data) => {
      getSchoolDraftPreview()
    })
  }

  function checkForDraft(postId) {
    sanityClient
      .fetch(
        /* js */ `
          *[_id == "drafts.${postId}"]{
            _id,
          }`
      )
      .then((draft) => {
        if (draft.length > 0) {
          getSchoolDraftPreview()
          listenDraft()
        } else {
          getSchoolPreview()
        }
      })
  }

  useEffect(() => {
    if (!!postId) {
      checkForDraft(postId)
    }
  }, [postId])

  useEffect(() => {
    setPreview(queryParams.get('livepreview'))
    if (queryParams.get('livepreview')) {
      watchSchool()
    } else {
      getSchool()
    }
  }, [location])

  // bg-[#862633]
  // bg-[#003b5c]
  // bg-[#77c5d5]
  // bg-[#ffc845]
  // bg-[#8f993e]
  // bg-[#ff7f41]

  // from-[#862633]
  // from-[#003b5c]
  // from-[#77c5d5]
  // from-[#ffc845]
  // from-[#8f993e]
  // from-[#ff7f41]

  // to-[#862633]
  // to-[#003b5c]
  // to-[#77c5d5]
  // to-[#ffc845]
  // to-[#8f993e]
  // to-[#ff7f41]

  return (
    <>
      <div>
        <NavBar year={year} />
        <div
          class={`fixed top-0 left-0 right-0 z-10 h-screen w-screen bg-gray-50
        ${story ? 'pointer-events-none opacity-0' : 'opacity-100'}
        transition-opacity duration-500 ease-in-out
        `}
        >
          <div class="flex h-full w-full items-center justify-center">
            <div class="h-20 w-20 animate-spin rounded-full border-4 border-t-sv-yellow border-r-sv-green border-l-sv-light-blue border-b-sv-crimson"></div>
          </div>
        </div>

        {story?.featuredVideo ? (
          <>
            {story?.video && (
              <div class="relative inset-0 flex w-full flex-col items-center justify-center overflow-hidden bg-black bg-gradient-to-tr from-gray-900 to-sv-navy/70 py-12 shadow-inner lg:py-24 lg:pb-32">
                <img
                  class="absolute inset-0 h-full w-full object-cover opacity-30 mix-blend-overlay grayscale filter"
                  src={urlFor(story.mainImage).width(1920).url()}
                  alt=""
                />
                <div class="relative w-full max-w-7xl px-8 text-white md:pb-48">
                  <div
                    class="overflow-hidden rounded-2xl transition-all duration-500 hover:shadow-2xl"
                    style={{
                      padding: '56.25% 0px 0px',
                      position: 'relative',
                    }}
                  >
                    {story?.video && story?.video?.includes('youtube') && (
                      <iframe
                        src={story?.video
                          ?.replace('watch?v=', 'embed/')
                          .replace('youtu.be/', 'youtube.com/embed/')}
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        style={{
                          position: 'absolute',
                          top: '0px',
                          left: '0px',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    )}
                    {story?.video && story?.video?.includes('vimeo') && (
                      <iframe
                        src={story?.video?.replace(
                          'vimeo.com/',
                          'player.vimeo.com/video/'
                        )}
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        style={{
                          position: 'absolute',
                          top: '0px',
                          left: '0px',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    )}
                  </div>
                  <h3 className="mt-8 font-condensed text-lg font-light uppercase text-sv-yellow lg:text-2xl">
                    {story?.videoOverline}
                  </h3>
                  <h4 className="mb-8 font-display text-3xl font-bold uppercase lg:text-5xl">
                    {story?.videoTitle}
                  </h4>
                  <p className="max-w-3xl font-body leading-loose lg:text-lg">
                    {story?.videoDescription}
                  </p>
                </div>
              </div>
            )}
          </>
        ) : (
          <div class="w-full">
            <div
              class={`h-64 w-screen bg-gradient-to-tr lg:h-96 bg-[${story?.imageColorStart?.value}] relative from-[${story?.imageColorStart?.value}] to-[${story?.imageColorEnd?.value}]`}
            >
              {story?.mainImage && (
                <img
                  src={urlFor(story.mainImage).width(1920).url()}
                  class="absolute inset-0 h-full w-full object-cover mix-blend-overlay"
                />
              )}
              <div class="relative mx-auto max-w-7xl md:px-8">
                <div class="relative w-full"></div>
              </div>
            </div>
          </div>
        )}

        <div class="relative mx-auto w-full max-w-7xl md:-mt-48 md:px-8 md:pt-16 lg:-mt-72 lg:pb-32">
          <div class="relative flex w-full flex-col lg:flex-row">
            <div class="relative flex rounded-2xl">
              <div class="relative mx-auto inline-block w-full bg-white p-8 shadow-2xl sm:w-auto md:rounded-lg lg:p-16">
                <div class="relative mx-auto h-auto w-full max-w-prose rounded-md lg:mx-0 lg:w-auto">
                  <div class="flex flex-col-reverse items-start md:flex-row md:items-center">
                    <div class="flex flex-col">
                      <h1 class="m-0 p-0">
                        <span class="mt-2 block font-display text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                          {story?.title}
                        </span>
                      </h1>
                      <div class="mt-6 mb-2 w-16 border-t-4 border-sv-green"></div>
                      <p class="m-0 p-0 font-condensed font-bold uppercase text-sv-crimson">
                        By {story?.firstByline}
                      </p>
                      <p class="m-0 p-0 font-condensed uppercase text-sv-crimson">
                        {story?.secondByline}
                      </p>
                    </div>
                  </div>
                  <div class="prose mt-8 max-w-prose prose-headings:font-display prose-headings:text-sv-crimson lg:prose-lg">
                    <PortableText
                      value={story?.body}
                      components={
                        // render quote block
                        {
                          types: {
                            quote: ({ children, value }) => (
                              <blockquote
                                cite="Benjamin Franklin"
                                className="my-6 border-sv-navy py-2 pl-2 text-2xl font-light leading-relaxed"
                              >
                                "{value?.quote}"
                                <span className="mt-3 block text-lg font-bold uppercase not-italic text-sv-crimson">
                                  {value?.originator}
                                </span>
                              </blockquote>
                            ),
                            bondInfographic: ({ children, value }) => (
                              <div class="not-prose">
                                <BondInfographic />
                              </div>
                            ),
                            image: ({ children, value }) => (
                              <div class="mb-8 flex flex-col items-center">
                                {value && (
                                  <>
                                    <img
                                      src={urlFor(value)
                                        .width(800)
                                        .height(600)
                                        .fit('crop')
                                        .url()}
                                      className="rounded-xl"
                                    />
                                    <span class="mt-0 text-sm font-light text-gray-500">
                                      {value?.caption}
                                    </span>
                                  </>
                                )}
                              </div>
                            ),
                          },
                        }
                      }
                    />

                    {story?.citations && (
                      <p class="citations mt-12 border-t border-sv-green pt-4 text-sm font-light">
                        {story?.citations}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {story?.infographicTitle && story?.infographics?.length > 0 && (
              <div class="relative mb-8 mt-32 lg:mt-96 lg:w-2/5">
                <div class="sticky top-24 mb-6 space-y-6 overflow-y-auto px-4 pb-16 lg:max-h-[calc(100vh-7em)]">
                  <div class="border-t-2 border-b-2 border-gray-300 px-4 py-4 text-left font-body text-2xl font-bold uppercase text-gray-900">
                    <p class="font-display text-base font-light">
                      {story?.infographicOverline || 'By the Numbers'}
                    </p>
                    {story?.infographicTitle && (
                      <p class="font-sans text-2xl font-black">
                        {story?.infographicTitle}
                      </p>
                    )}
                  </div>
                  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-1">
                    {story?.infographics?.map((infographic, index) => (
                      <Infographic
                        key={index}
                        title={infographic.title}
                        description={infographic.description}
                        icon={infographic.icon}
                        color={story?.iconColor?.value}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Use story.video to render a video iframe from a youtube or vimeo link */}
      {!story?.featuredVideo && story?.video && (
        <div class="relative flex w-full flex-col items-center justify-center overflow-hidden bg-gradient-to-tr from-gray-900 to-sv-navy py-12 shadow-inner lg:py-24">
          <img
            class="absolute inset-0 h-full w-full object-cover opacity-10 mix-blend-overlay grayscale filter"
            src="https://images.unsplash.com/photo-1546453667-8a8d2d07bc20?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=987&amp;q=80"
          />
          <div class="relative w-full max-w-2xl px-8">
            <div
              class="overflow-hidden rounded-2xl transition-all duration-500 hover:shadow-2xl"
              style={{
                padding: '56.25% 0px 0px',
                position: 'relative',
              }}
            >
              {story?.video && story?.video?.includes('youtube') && (
                <iframe
                  src={story?.video
                    ?.replace('watch?v=', 'embed/')
                    .replace('youtu.be/', 'youtube.com/embed/')}
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
              {story?.video && story?.video?.includes('vimeo') && (
                <iframe
                  src={story?.video?.replace(
                    'vimeo.com/',
                    'player.vimeo.com/video/'
                  )}
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <Footer />

      {preview && (
        <div
          className={`fixed bottom-0 left-0 z-10 ml-2 mb-2 max-w-[26rem] transform transition-all duration-500 sm:max-w-[810px] ${
            showCard ? 'translate-y-0' : 'translate-y-[calc(100%-2.5rem)]'
          }`}
          onClick={() => setShowCard(!showCard)}
        >
          <div className="mb-2 inline-block min-w-[170px] cursor-pointer rounded-full bg-black px-4 py-2 text-center text-white hover:bg-gray-800">
            {showCard ? 'Hide' : 'Show'} Card Preview
          </div>
          <div className="mt-1 flex flex-row gap-2">
            <div
              className={`group flex transform flex-col overflow-hidden rounded-3xl border-none shadow-sm outline-none transition-all duration-500 visited:opacity-50 hover:-translate-y-1 hover:rounded-none hover:opacity-100 hover:shadow-2xl focus:-translate-y-1 focus:rounded-none focus:shadow-2xl motion-reduce:transition-none`}
              style={{
                filter: 'blur(0px)',
              }}
            >
              <div class="relative flex-shrink-0 overflow-hidden bg-black">
                {story?.mainImage ? (
                  <img
                    class="h-72 w-full transform object-cover opacity-100 filter transition-all duration-500 group-hover:-translate-y-12 group-hover:scale-105 group-hover:grayscale-0 group-focus:-translate-y-12 group-focus:scale-105 group-focus:grayscale-0 md:h-96"
                    src={
                      story?.cardImage
                        ? urlFor(story?.cardImage)?.width(800).url()
                        : urlFor(story?.mainImage)?.width(800).url()
                    }
                    alt={story?.mainImage?.alt}
                  />
                ) : (
                  <div class="h-72 w-full transform object-cover opacity-100 filter transition-all duration-500 group-hover:-translate-y-12 group-hover:scale-105 group-hover:grayscale-0 group-focus:-translate-y-12 group-focus:scale-105 group-focus:grayscale-0 md:h-96"></div>
                )}
                <div class="absolute left-0 right-0 bottom-0 h-1/2 bg-gradient-to-t from-black to-transparent opacity-70 transition-all duration-500 group-hover:h-full group-hover:opacity-100"></div>
              </div>
              <div class="pointer-events-none absolute bottom-0 w-full translate-y-40 transform transition-all duration-500 group-hover:translate-y-0 group-focus:translate-y-0">
                <div class="flex flex-1 flex-col justify-between bg-transparent p-6">
                  <div class="flex-1">
                    <p class="text-sm font-medium text-white">
                      <span class="">Make a Difference.</span>
                    </p>
                    <div class="mt-2 block">
                      <p class="text-xl font-semibold text-white">
                        {story?.title}
                      </p>
                      <p class="mt-3 text-base text-gray-100 line-clamp-3">
                        {story?.seoDescription}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex items-center bg-gray-50 p-6">
                  <div class="flex w-full justify-between">
                    <p class="text-sm font-medium text-gray-900">
                      <span class="hover:underline">{story?.firstByline}</span>
                    </p>
                    {story?.timeToRead && (
                      <div class="flex space-x-1 text-sm text-gray-500">
                        <span>{story?.timeToRead} min read </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`group flex -translate-y-1 transform flex-col overflow-hidden rounded-none rounded-3xl border-none opacity-100 shadow-sm shadow-2xl outline-none transition-all duration-500 visited:opacity-50 focus:-translate-y-1 focus:rounded-none focus:shadow-2xl motion-reduce:transition-none`}
              style={{
                filter: 'blur(0px)',
              }}
            >
              <div class="relative flex-shrink-0 overflow-hidden bg-black">
                {story?.mainImage ? (
                  <img
                    class="h-72 w-full -translate-y-12 scale-105 transform object-cover opacity-100 grayscale-0 filter transition-all duration-500 group-focus:-translate-y-12 group-focus:scale-105 group-focus:grayscale-0 md:h-96"
                    src={
                      story?.cardImage
                        ? urlFor(story?.cardImage)?.width(800).url()
                        : urlFor(story?.mainImage)?.width(800).url()
                    }
                    alt={story?.mainImage?.alt}
                  />
                ) : (
                  <div class="h-72 w-full -translate-y-12 scale-105 transform object-cover opacity-100 grayscale-0 filter transition-all duration-500 group-focus:-translate-y-12 group-focus:scale-105 group-focus:grayscale-0 md:h-96"></div>
                )}
                <div class="absolute left-0 right-0 bottom-0 h-1/2 h-full bg-gradient-to-t from-black to-transparent opacity-70 opacity-100 transition-all duration-500"></div>
              </div>
              <div class="pointer-events-none absolute bottom-0 w-full translate-y-40 translate-y-0 transform transition-all duration-500 group-focus:translate-y-0">
                <div class="flex flex-1 flex-col justify-between bg-transparent p-6">
                  <div class="flex-1">
                    <p class="text-sm font-medium text-white">
                      <span class="">Make a Difference.</span>
                    </p>
                    <div class="mt-2 block">
                      <p class="text-xl font-semibold text-white">
                        {story?.title}
                      </p>
                      <p class="mt-3 text-base text-gray-100 line-clamp-3">
                        {story?.seoDescription}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex items-center bg-gray-50 p-6">
                  <div class="flex w-full justify-between">
                    <p class="text-sm font-medium text-gray-900">
                      <span class="hover:underline">{story?.firstByline}</span>
                    </p>
                    {story?.timeToRead && (
                      <div class="flex space-x-1 text-sm text-gray-500">
                        <span>{story?.timeToRead} min read </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
