import Spline from '@splinetool/react-spline'

export const Hero2024 = () => {
  return (
    <>
      <div className="relative hidden bg-black md:block">
        <div className="absolute inset-0 bg-sv-navy"></div>
        <iframe
          src="https://my.spline.design/airplanehero-2fe56cef9ddf41885f10f1d0835d6506/"
          frameborder="0"
          width="100%"
          height="100%"
          className=" relative aspect-[2/3] h-auto w-full md:aspect-video"
        ></iframe>
        <div className="absolute bottom-0 h-14 w-full bg-white"></div>
      </div>
      <div className="relative md:hidden">
        <div class="absolute top-1/2 -translate-y-1/2 px-4">
          <div className="absolute inset-0 -z-10 rounded-full bg-sv-navy/50 blur-2xl"></div>
          <h1 class="mt-4 text-center text-4xl font-extrabold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 lg:text-left xl:text-6xl">
            <span
              class="block uppercase"
              style={{ fontFamily: 'Roboto Condensed' }}
            >
              Where Futures
              <br />
              Take Flight
            </span>
          </h1>
          <p class="mt-3 text-center text-base text-gray-100 sm:mt-5 sm:text-xl lg:max-w-lg lg:text-left lg:text-lg xl:text-xl">
            Our students are the creators, innovators, and leaders who will
            shape the future and drive the success of our community, state, and
            nation. The sky is the limit. Public education is the catalyst from
            which our future is born.
          </p>
        </div>
        <img
          class="w-full"
          src="../wp-content/themes/magazine/2024-airplane.jpg"
          alt=""
        />
      </div>
    </>
  )
}
