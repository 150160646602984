export const PhotoInfographicCard = ({
  title,
  description,
  imgSrc,
  fullHeight,
  classes = '',
}) => {
  return (
    <div
      class={
        fullHeight
          ? `relative row-span-2 flex flex-col items-end justify-between overflow-hidden rounded-xl bg-white px-5 pt-5 pb-6 shadow-xl shadow-sv-crimson/10 ring-1 ring-sv-crimson/5 sm:mx-auto ${classes}`
          : `group relative flex flex-col justify-between overflow-hidden rounded-xl bg-white p-5 shadow-xl shadow-sv-crimson/10 ring-1 ring-sv-crimson/5 sm:mx-auto ${classes}`
      }
    >
      <div
        class={
          fullHeight
            ? `relative -m-5 mb-0 flex h-full w-[calc(100%+theme(spacing.10))] overflow-hidden bg-gradient-to-tr from-sv-crimson to-sv-light-blue transition-colors after:absolute after:bottom-0 after:h-24 after:w-full after:bg-gradient-to-t after:from-white after:via-white/70 after:to-transparent`
            : `aspect-3/2 relative -m-5 mb-0 w-[calc(100%+theme(spacing.10))] overflow-hidden bg-gradient-to-tr from-sv-crimson to-sv-light-blue transition-colors after:absolute after:bottom-0 after:h-24 after:w-full after:bg-gradient-to-t after:from-white after:via-white/70 after:to-transparent`
        }
      >
        <img
          src={imgSrc}
          alt=""
          className="relative h-[101%] w-[101%] object-cover mix-blend-screen grayscale"
        />
      </div>
      <div className="relative mb-2">
        <p class="mb-1.5 text-sm font-medium text-slate-700">{title}</p>
        <p class="text-sm font-light leading-normal text-slate-600">
          {description}
        </p>
      </div>
    </div>
  )
}
