import { useState } from 'react'

export const InfographicCard = ({ title, description, imgSrc }) => {
  return (
    <div class="relative flex flex-col items-center overflow-hidden rounded-xl bg-white p-5 shadow-xl shadow-sv-navy/10 ring-1 ring-sv-navy/5 @lg:flex-row @3xl:flex-col sm:mx-auto">
      <div class="relative -m-5 mb-0 h-48 w-[calc(100%+theme(spacing.10))] min-w-0 shrink-0 overflow-hidden bg-sv-light-blue/10 transition-colors after:absolute after:bottom-0 after:h-24 after:w-full after:bg-gradient-to-t after:from-white after:via-white/70 after:to-transparent @lg:-mb-5 @lg:w-64 @lg:after:right-0 @lg:after:h-full @lg:after:w-24 @lg:after:bg-gradient-to-l @3xl:mb-0 @3xl:w-[calc(100%+theme(spacing.10))] @3xl:after:h-24 @3xl:after:w-full @3xl:after:bg-gradient-to-t">
        <img
          src={imgSrc}
          alt=""
          className="relative h-full w-full object-contain object-bottom @lg:object-left @3xl:object-bottom"
        />
      </div>
      <div className="relative -mt-6 @lg:mt-0 @3xl:-mt-6">
        <p class="mb-1.5 text-sm font-medium text-slate-700">{title}</p>
        <p class={`text-sm font-light leading-normal text-slate-600`}>
          {description}
        </p>
      </div>
    </div>
  )
}
