import { NavBar } from '../components/NavBar';
import { Footer } from '../components/Footer';

export const Print = ({ year }) => {
  return (
    <>
      <NavBar year={year} />
      <div class="bg-gradient-to-b from-white to-gray-50 pb-48">
        <div class="max-w-7xl mx-auto pt-24 px-4 sm:px-6 lg:px-8">
          <div class="sm:flex sm:flex-col sm:align-center border-b border-gray-300 pb-12">
            <h1 class="text-5xl font-extrabold text-gray-900">Contact Us</h1>
            <p class="mt-5 text-xl text-gray-500">
              Request a printed copy of the magazine or submit a
              question/comment.{' '}
            </p>
          </div>
          <div class="flex flex-col md:flex-row-reverse gap-8 mt-24">
            <div class="prose max-w-prose prose-exclude prose-lg col-span-2 pb-24 md:pb-0 md:border-l md:pl-8 border-gray-200">
              <h2>Request a Print Publication</h2>
              <p>
                If you would like to receive a printed publication or submit a
                question/comment, please email us at{' '}
                <a
                  class="whitespace-nowrap"
                  href="mailto:communications-group@svvsd.org"
                >
                  communications-group@svvsd.org
                </a>
                .
              </p>
              <p>
                Thank you,
                <br /> District Communications{' '}
              </p>
            </div>
            <div>
              <img
                src="/wp-content/themes/magazine/2024cover.png"
                class="rounded-2xl border border-gray-300 max-w-sm object-cover w-full h-full"
                alt="2024 Magazine Cover"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
