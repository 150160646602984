export const Hero2022 = () => {
  return (
    <div class="relative overflow-hidden">
      <main>
        <div class="pt-10 bg-sv-navy sm:pt-16 lg:pt-8 lg:pb-48 lg:overflow-hidden">
          <img
            class="absolute inset-0 object-cover object-center w-full h-full mix-blend-multiply grayscale filter filter blur-sm scale-105 transform"
            src="../wp-content/themes/magazine/captoss.jpg"
          />
          <div class="mx-auto max-w-7xl lg:px-8 relative z-10">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div class="lg:py-24">
                  <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span
                      class="block uppercase"
                      style={{ fontFamily: 'Roboto Condensed' }}
                    >
                      Our{' '}
                      <span
                        class="font-normal normal-case"
                        style={{ fontFamily: 'Comforter' }}
                      >
                        connect<span class="text-sv-yellow">ED</span>
                      </span>{' '}
                      World
                    </span>
                  </h1>
                  <p class="mt-3 text-base text-gray-100 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    {' '}
                    Public Education is the catalyst for innovation and
                    community advancement. It is the common thread from which
                    our children grow into the leaders who will shape the future
                    of our world.{' '}
                  </p>
                </div>
              </div>
              <div class="mt-12 lg:m-0 lg:relative">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                  <img
                    class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="../wp-content/themes/magazine/2022illustration.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
