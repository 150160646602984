import { NavBar } from '../components/NavBar'
import { Footer } from '../components/Footer'

export const About = ({ year }) => {
  return (
    <>
      <NavBar year={year} />
      <div class="bg-gradient-to-b from-white to-gray-50 pb-48">
        <div class="mx-auto max-w-7xl px-4 pt-24 sm:px-6 lg:px-8">
          <div class="sm:align-center border-b border-gray-300 pb-12 sm:flex sm:flex-col">
            <h1 class="text-5xl font-extrabold text-gray-900">
              About St. Vrainnovation
            </h1>
            <p class="mt-5 text-xl text-gray-500">
              A publication from St. Vrain Valley Schools
            </p>
          </div>
          <div class="mt-24 flex flex-col justify-center gap-8 md:flex-row-reverse">
            <div class="prose-exclude prose prose-lg col-span-2 border-gray-200 pb-24 md:border-l md:pb-0 md:pl-8">
              <h2>From the Publisher</h2>
              <p>Dear St. Vrain Valley Community,</p>
              <p>
                Welcome to ST. VRAINNOVATION, a publication celebrating the
                impact of education in our community. Public education plays one
                of the most significant roles in the advancement of our nation.
                To ensure the success of our children, economy, and our future,
                it is essential that we prepare students to meet the challenges
                they will face.
              </p>
              <p>
                Please join us in celebrating the incredible achievements of our
                students, staff, and community. Together, we are advancing our
                future.
              </p>
              <p>Sincerely,</p>
              <p>
                Don Haddad, Ed.D.
                <br /> Superintendent, St. Vrain Valley Schools
                <br /> @SVVSDSupt{' '}
              </p>
            </div>
            <ul class="max-w-sm divide-y divide-gray-200">
              <p class="pb-4">
                ST. VRAINNOVATION was produced in-house by St. Vrain Valley
                Schools’ Department of Communications.
              </p>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">Liz Allen</p>
                  <p class="text-sm text-gray-800">
                    Graphic Design and Digital Content Specialist
                  </p>
                </div>
              </li>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">
                    Caroline Chutkow
                  </p>
                  <p class="text-sm text-gray-800">
                    Director of Communications and Marketing
                  </p>
                </div>
              </li>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">Aaron Ford</p>
                  <p class="text-sm text-gray-800">
                    Senior Web Development and Systems Administrator
                  </p>
                </div>
              </li>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">
                    Heather Hammarstrom
                  </p>
                  <p class="text-sm text-gray-800">
                    Marketing and Communications Specialist
                  </p>
                </div>
              </li>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">Jane Lim</p>
                  <p class="text-sm text-gray-800">
                    Communications Department Secretary
                  </p>
                </div>
              </li>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">
                    Kerri McDermid, Ed.D.
                  </p>
                  <p class="text-sm text-gray-800">
                    Chief Communications and Global Impact Officer
                  </p>
                </div>
              </li>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">Eunice Peinado</p>
                  <p class="text-sm text-gray-800">
                    Marketing and Communications Specialist
                  </p>
                </div>
              </li>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">Colin Rickman</p>
                  <p class="text-sm text-gray-800">
                    Director of Communications and Engagement
                  </p>
                </div>
              </li>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">Owen Willis</p>
                  <p class="text-sm text-gray-800">Communications Intern</p>
                </div>
              </li>

              <li class="flex py-4">
                <div class="">
                  <p class="">Contributors</p>
                </div>
              </li>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">Sienna Arellano</p>
                  <p class="text-sm text-gray-800">Niwot High, Class of 2024</p>
                </div>
              </li>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">Andy Freeman</p>
                  <p class="text-sm text-gray-800">
                    Teacher, Longs Peak Middle School
                  </p>
                </div>
              </li>
              <li class="flex py-4">
                <div class="">
                  <p class="text-sm font-bold text-gray-900">Lewis Geyer</p>
                  <p class="text-sm text-gray-800">
                    Para-Educator, Altona Middle School
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
