import { NavBar } from '../components/NavBar';
import { Footer } from '../components/Footer';

export const Archive = ({ year }) => {
  return (
    <>
      <NavBar year={year} />

      <div class="bg-gradient-to-b from-white to-gray-50 pb-48">
        <div class="max-w-7xl mx-auto pt-24 px-4 sm:px-6 lg:px-8">
          <div class="sm:flex sm:flex-col sm:align-center">
            <h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">
              Magazine Archive
            </h1>
            <p class="mt-5 text-xl text-gray-500 sm:text-center">
              This year's magazine is our sixth edition. Check out previous
              magazines below.
            </p>
          </div>
          <div class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-6">
            <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 xl:col-span-2">
              <div class="p-6">
                <h2 class="text-lg leading-6 font-bold text-center text-gray-900">
                  2022-2023
                </h2>
                <img
                  class="shadow-md rounded-md w-full h-auto mt-4"
                  src="wp-content/themes/magazine/2023cover.png"
                  alt=""
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://issuu.com/svvsd/docs/stvrainnovationmagazine_2023"
                  class="mt-8 block w-full bg-sv-crimson border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-opacity-90"
                >
                  Download Print Version
                </a>
                <a
                  href="2023/"
                  class="mt-2 block w-full bg-sv-crimson bg-opacity-0 border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-sv-crimson text-center hover:bg-opacity-10"
                >
                  Access Online Version
                </a>
              </div>
            </div>
            <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 xl:col-span-2">
              <div class="p-6">
                <h2 class="text-lg leading-6 font-bold text-center text-gray-900">
                  2021-2022
                </h2>
                <img
                  class="shadow-md rounded-md w-full h-auto mt-4"
                  src="wp-content/themes/magazine/2022-magazine.png"
                  alt=""
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://issuu.com/svvsd/docs/magazine-2021-our-conneced-world"
                  class="mt-8 block w-full bg-sv-crimson border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-opacity-90"
                >
                  Download Print Version
                </a>
                <a
                  href="2022/"
                  class="mt-2 block w-full bg-sv-crimson bg-opacity-0 border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-sv-crimson text-center hover:bg-opacity-10"
                >
                  Access Online Version
                </a>
              </div>
            </div>
            <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 xl:col-span-2">
              <div class="p-6">
                <h2 class="text-lg leading-6 font-bold text-center text-gray-900">
                  2019-2020
                </h2>
                <img
                  class="shadow-md rounded-md w-full h-auto mt-4"
                  src="wp-content/themes/magazine/magazine2020.jpg"
                  alt=""
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://issuu.com/svvsd/docs/2019-2020-stvrainnovation-magazine?fr=sMWNiYTQzODIzOA"
                  class="mt-8 block w-full bg-sv-crimson border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-opacity-90"
                >
                  Download Print Version
                </a>
                <a
                  href="2019/"
                  class="mt-2 block w-full bg-sv-crimson bg-opacity-0 border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-sv-crimson text-center hover:bg-opacity-10"
                >
                  Access Online Version
                </a>
              </div>
            </div>
            <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 xl:col-span-2">
              <div class="p-6">
                <h2 class="text-lg leading-6 font-bold text-center text-gray-900">
                  2018-2019
                </h2>
                <img
                  class="shadow-md rounded-md w-full h-auto mt-4"
                  src="wp-content/themes/magazine/2019cover.jpg"
                  alt=""
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://issuu.com/svvsd/docs/2018_st.vrainnovation_magazine?fr=sM2E4MTQzODIzOA"
                  class="mt-8 block w-full bg-sv-crimson border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-opacity-90"
                >
                  Download Print Version
                </a>
                <a
                  href="2018/"
                  class="mt-2 block w-full bg-sv-crimson bg-opacity-0 border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-sv-crimson text-center hover:bg-opacity-10"
                >
                  Access Online Version
                </a>
              </div>
            </div>
            <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 xl:col-span-2">
              <div class="p-6">
                <h2 class="text-lg leading-6 font-bold text-center text-gray-900">
                  2017-2018
                </h2>
                <img
                  class="shadow-md rounded-md w-full h-auto mt-4"
                  src="wp-content/themes/magazine/2018cover.jpg"
                  alt=""
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://issuu.com/svvsd/docs/2017-magazine?fr=sZjU1OTQzODIzOA"
                  class="mt-8 block w-full bg-sv-crimson border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-opacity-90"
                >
                  Download Print Version
                </a>
                <a
                  href="2017/"
                  class="mt-2 block w-full bg-sv-crimson bg-opacity-0 border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-sv-crimson text-center hover:bg-opacity-10"
                >
                  Access Online Version
                </a>
              </div>
            </div>
            <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 xl:col-span-2">
              <div class="p-6">
                <h2 class="text-lg leading-6 font-bold text-center text-gray-900">
                  2016-2017
                </h2>
                <img
                  class="shadow-md rounded-md w-full h-auto mt-4"
                  src="wp-content/themes/magazine/2017cover.jpg"
                  alt=""
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://issuu.com/svvsd/docs/2016-august-stvrainnovation?e=3716635/37908927"
                  class="mt-8 block w-full bg-sv-crimson border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-opacity-90"
                >
                  Download Print Version
                </a>
                <a
                  href="2016/"
                  class="mt-2 block w-full bg-sv-crimson bg-opacity-0 border-2 border-sv-crimson rounded-md py-2 text-sm font-semibold text-sv-crimson text-center hover:bg-opacity-10"
                >
                  Access Online Version
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
