export const Hero2023 = () => {
  return (
    <div class="relative overflow-hidden">
      <main className="bg-white">
        <div class="pt-10 bg-gradient-to-tr from-sv-navy to-sv-navy/50 sm:pt-16 lg:pt-8 lg:pb-48 lg:overflow-hidden -hue-rotate-[18deg]">
          <img
            class="absolute inset-0  object-cover object-center w-[calc(10vw+80rem)] h-full scale-105 mix-blend-overlay transform"
            src="https://images.unsplash.com/photo-1464802686167-b939a6910659?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1700&q=80"
          />
          <div class="mx-auto max-w-7xl lg:px-8 relative z-10">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div class="lg:py-24">
                  <h1 class="mt-4 text-4xl tracking-tight text-center lg:text-left font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span
                      class="block uppercase"
                      style={{ fontFamily: 'Roboto Condensed' }}
                    >
                      To Infinity and Beyond
                    </span>
                  </h1>
                  <p class="mt-3 text-base text-center lg:text-left lg:max-w-lg text-gray-100 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Just as President John F. Kennedy inspired a generation to
                    reach for the moon, our students are driving innovation and
                    achievement in a world of infinite possibility. Public
                    education is the catalyst from which our future is born.
                  </p>
                </div>
              </div>
              <div class="mt-12 lg:m-0 lg:relative hidden lg:block">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                  <img
                    class="hue-rotate-[10deg] w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none transform lg:translate-y-[150px] lg:translate-x-[100px] lg:scale-[1.9] z-10 mix-blend-multiply"
                    src="../wp-content/themes/magazine/2023-cover-foreground.png"
                    alt=""
                  />
                  <div className="absolute lg:relative h-full w-full bg-white lg:translate-y-[150px] lg:translate-x-[500px] lg:scale-[1.9] -z-10" />
                </div>
              </div>
              <div class="lg:hidden mt-12">
                <div class="pr-8">
                  <img
                    class="hue-rotate-[10deg] w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none transform lg:translate-y-[150px] lg:translate-x-[100px] lg:scale-[1.9] z-10 mix-blend-multiply"
                    src="../wp-content/themes/magazine/2023-cover-foreground-mobile.png"
                    alt=""
                  />
                  <div className="absolute lg:relative h-full w-full bg-white lg:translate-y-[150px] lg:translate-x-[500px] lg:scale-[1.9] -z-10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
