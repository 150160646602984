import sanityClient from '@sanity/client';

export default sanityClient({
  projectId: '4rzcj4vr',
  dataset: 'production',
  useCdn: true,
});

export const previewClient = sanityClient({
  projectId: '4rzcj4vr',
  dataset: 'production',
  apiVersion: '2021-08-29',
  useCdn: false,
  withCredentials: true,
});
