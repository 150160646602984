export const Infographic = ({ title, description, icon, color }) => {
  // text-[#862633]
  // text-[#003b5c]
  // text-[#77c5d5]
  // text-[#ffc845]
  // text-[#8f993e]
  // text-[#ff7f41]
  return (
    <div class="p-4 w-full data rounded-lg">
      <span class="font-display font-bold text-4xl flex items-center">
        {icon && (
          <i
            class={`fad text-3xl mr-2 fa-${icon} text-[${color && color}]`}
          ></i>
        )}
        {title && title}
      </span>
      {description && (
        <p class="m-0 p-0 max-w-prose font-display text-gray-800">
          {description}
        </p>
      )}
    </div>
  );
};
